import React, { useState } from "react";

import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { ButtunSubmit, Form, ScreenLoading } from "../../../create/styles";

import { IForecastSeller } from "..";
import { Input } from "../../../../components/Form/Input";
import Loading from "../../../../components/Loading";
import {
  Container,
  ContainerForm,
} from "../../../../components/ModalModificationHeaderDatasheet/styled";
import api from "../../../../service/api";
import { SelectClients } from "../SelectClients";

interface ModalModificationForecastSellerProps {
  onRequestUpdate?: () => void;
  onRequestClose: () => void;
  data: IForecastSeller;

  disableChangeClient?: boolean;
}

export const ModalModificationForecastSeller: React.FC<
  ModalModificationForecastSellerProps
> = ({
  onRequestUpdate,
  onRequestClose,
  data,
  disableChangeClient = false,
}) => {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [loadingPage] = useState(false);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      campaignValue: data.campaignValue,
      goalsValue: data.goalsValue,
      clients: data.forecast_data.map((item) => ({
        cod: item.client_pj.cod,
        cnpj: item.client_pj.cnpj,
        company_name: item.client_pj.company_name,
        historic1: item.client_pj.historic1,
        historic2: item.client_pj.historic2,
        historic3: item.client_pj.historic3,
        historic4: item.client_pj.historic4,
      })),
    },
    validationSchema: Yup.object({
      campaignValue: Yup.number()
        .typeError("Campanha deve ser um número")
        .nullable(),
      goalsValue: Yup.number().typeError("Meta deve ser um número").nullable(),
    }),

    onSubmit: async (data) => await handleSubmitUpdate(data),
  });

  async function handleSubmitUpdate(_props: any) {
    setLoading(true);

    try {
      await api.put(`/forecast/forecastseller/${data.id}`, {
        campaignValue: Number(formik.values.campaignValue),
        goalsValue: Number(formik.values.goalsValue),
      });

      if (!disableChangeClient) {
        await api.patch(`/forecast/forecastseller/${data.id}`, {
          clients: formik.values.clients,
        });
      }

      setLoading(false);
      onRequestClose();

      if (onRequestUpdate) onRequestUpdate();

      addToast("Alterações realizada com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      setLoading(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <Form
        onSubmit={
          loading
            ? (e) => {
                e.preventDefault();
              }
            : formik.handleSubmit
        }
      >
        <ContainerForm>
          <div className="containerForm">
            {!loadingPage ? (
              <>
                <Input
                  label="Campanha"
                  {...formik.getFieldProps("campaignValue")}
                  error={
                    formik.touched.campaignValue && formik.errors.campaignValue
                      ? formik.errors.campaignValue
                      : undefined
                  }
                />

                {data.forecast.isGoals && (
                  <Input
                    label="Valor meta"
                    {...formik.getFieldProps("goalsValue")}
                    error={
                      formik.touched.goalsValue && formik.errors.goalsValue
                        ? formik.errors.goalsValue
                        : undefined
                    }
                  />
                )}

                <SelectClients
                  disabled={disableChangeClient}
                  clients={(formik.values.clients as any) ?? []}
                  onChangeClients={(clients) => {
                    formik.setFieldValue("clients", clients ?? []);
                  }}
                  histories={{
                    historic1: data?.forecast?.historic1 ?? "-",
                    historic2: data?.forecast?.historic2 ?? "-",
                    historic3: data?.forecast?.historic3 ?? "-",
                    historic4: data?.forecast?.historic4 ?? "-",
                  }}
                  style={{ maxHeight: "24rem" }}
                />
              </>
            ) : (
              <ScreenLoading>
                <Loading
                  colorLoading="#333"
                  size={40}
                  borderColor={"#888"}
                  borderSize={5}
                />
              </ScreenLoading>
            )}
          </div>

          <div className="containerFormButton">
            <ButtunSubmit style={{ margin: 0 }} type="submit">
              {loading ? (
                <div>
                  <Loading borderSize={2} size={20} />
                </div>
              ) : (
                "Alterar Forecast"
              )}
            </ButtunSubmit>
          </div>
        </ContainerForm>
      </Form>
    </Container>
  );
};
