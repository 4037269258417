import { useQuery, UseQueryResult } from "react-query";
import { OptionsFilter } from "../../@types";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";
import { Seller } from "./useSeller";

export type ForecastSeller = {
  id: number;

  goalsValue: number;

  apparel: number;
  equipment: number;
  footwear: number;
  campaignValue: number;

  clientTotal: number;
  cityTotal: number;

  shippingTotal: number;
  shippingNotSend: number;
  shippingSend: number;

  sale_completed_qtd: number | null;
  sale_completed_value: number | null;

  branded_purchase_qtd: number | null;
  branded_purchase_value: number | null;

  receipt_products_qtd: number | null;
  receipt_products_value: number | null;

  review: number;

  status: {
    cod: number;
    name_manager: string;
    color_manager: string;
    name_seller: string;
    color_seller: string;
    finisher: boolean;
  };

  seller: Seller;
};

export type ForecastSellerFilters = {
  seller?: number;
  status?: string;
};

export interface FilterOptions {
  status: OptionsFilter[];
}

type GetForecastsSellerResponse = {
  forecastsSeller: ForecastSeller[];
  filters: FilterOptions;
  totalCount: number;
};

interface ForecastSellerApiResponse extends ResponsePanelApiDefault {
  contents: ForecastSeller[];
  filters: FilterOptions;
}

export async function getForecastsSeller(
  forecastId: number,
  page: number,
  filter?: ForecastSellerFilters,
  registersPerPag?: number
): Promise<GetForecastsSellerResponse> {
  const { data } = await api.get<ForecastSellerApiResponse>(
    `/forecast/shippinganalysis/${forecastId}`,
    {
      params: {
        pageRequest: page - 1,
        limitRequest: registersPerPag,
        ...filter,
      },
    }
  );

  const forecastsSeller = data.contents;
  const totalCount = data.total;
  const filters = data.filters;

  return {
    totalCount,
    forecastsSeller,
    filters,
  };
}

export function useForecastSellers(
  forecastId: number,
  page: number,
  filter?: ForecastSellerFilters,
  registersPerPag?: number
): UseQueryResult<GetForecastsSellerResponse> {
  return useQuery(
    ["forecasts-seller", forecastId, page, filter],
    () => getForecastsSeller(forecastId, page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getOneForecastsSeller(
  id?: number
): Promise<ForecastSeller | undefined> {
  if (!id && id !== 0) return undefined;

  const { data } = await api.get<ForecastSeller>(`/clientPJ/show/${id}`);

  const normalized = {
    ...data,
  };

  return normalized;
}

export function useOneForecastSeller(
  id?: number
): UseQueryResult<ForecastSeller> | undefined {
  return useQuery(["forecast-seller", id], () => getOneForecastsSeller(id), {
    // staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
