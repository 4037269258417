import { useQuery, UseQueryResult } from "react-query";
import api from "../../service/api";

export type GroupProduct = {
  cod: number;
  descrpition: string;
};

export type GroupProductFilter = {
  descrpition?: string;
  active?: number;
};

export async function getGroupProducts(
  page: number,
  filter: GroupProductFilter,
  registersPerPag?: number
): Promise<GroupProduct[]> {
  const { data } = await api.get<{ contents: GroupProduct[] }>(
    "/group-product",
    {
      params: {
        pageRequest: page - 1,
        limitRequest: registersPerPag,
        ...filter,
      },
    }
  );

  return data.contents;
}

export function useGroupProducts(
  page: number,
  filter: GroupProductFilter,
  registersPerPag?: number
): UseQueryResult<GroupProduct[]> {
  return useQuery(
    ["group-products", page, registersPerPag, filter],
    () => getGroupProducts(page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}
