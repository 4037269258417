import fileSize from "filesize";
import React from "react";
import { FaFile } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
import { ContainerPreviews } from "../../../../../components/Form/Dropzone/styles";
import { RegisterSellerFile } from "../../../../../hook/queries/useRegisterSeller";
import api from "../../../../../service/api";
import { downloadFilaBase64 } from "../../../../../utils/downloadFilaBase64";

interface Props {
  files?: RegisterSellerFile[];
}

export function ContainerFiles({ files }: Props) {
  const { addToast } = useToasts();

  async function handleDownloadFile(file: RegisterSellerFile) {
    addToast("Baixando...", {
      appearance: "info",
      autoDismiss: true,
    });

    const getFile = await api.post<{
      file: { fileBase64: string; name: string; mimetype: string };
    }>(`/register-seller/get-file/${file.id}`);

    if (getFile.data.file.fileBase64) {
      downloadFilaBase64({
        base64: getFile.data.file.fileBase64,
        filename: getFile.data.file.name,
        mimetype: getFile.data.file.mimetype,
      });
    }
  }

  return (
    <ContainerPreviews
      style={{
        overflowY: "auto",
        maxHeight: "25vh",
        marginBottom: "1rem",
      }}
    >
      {files?.map((file) => (
        <li
          onClick={() => {
            handleDownloadFile(file);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <FaFile size={"1.5rem"} color="#333" />
          <div className="fileInfo">
            <div>
              <strong>{file.name}</strong>
              <span>{fileSize(file.size)}</span>
            </div>
          </div>
        </li>
      ))}

      {(files?.length ?? 0) <= 0 && (
        <span style={{ fontWeight: "lighter", color: "#455" }}>
          Sem arquivos disponíveis
        </span>
      )}
    </ContainerPreviews>
  );
}
