import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { useFormik } from "formik";

import { IoReload } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { Pagination } from "../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import {
  RegisterSeller,
  useRegisterSellers,
} from "../../../hook/queries/useRegisterSeller";
import { SellerFilters } from "../../../hook/queries/useSeller";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";

const columns: FieldProps<RegisterSeller>[] = [
  {
    name: "fullName",
    field: "NOME COMPLETO",
    viewList: true,
  },
  {
    name: "status",
    field: "SITUAÇÃO",
    viewList: true,
    render: (invoice) => {
      return (
        <nav
          style={{
            color: "#000",
            fontWeight: "bold",
            backgroundColor: invoice.status.color,
            borderRadius: 6,
            fontSize: 12,
            padding: "4px 0",
          }}
        >
          {invoice.status.name}
        </nav>
      );
    },
  },
  {
    name: "aliasName",
    field: "ABREVIAÇÃO",
    viewList: true,
  },
  {
    name: "email",
    field: "EMAIL",
    viewList: true,
  },
  {
    name: "phone",
    field: "TELEFONE",
    viewList: true,
  },
];

type Props = {
  admin?: boolean;
};

const PanelRegisterSellers: React.FC<Props> = ({ admin }) => {
  const history = useHistory();
  const query = useQueryParams();

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });

  const [viewFilter, setViewFilter] = useState(false);

  const formikFilter = useFormik({
    initialValues: {
      aliasName: "",
      fullName: "",
      email: "",
      status: "",
    },
    onSubmit: (data) => {
      handleFilter(data as any);
    },
  });

  const [filters, setFilters] = useState<SellerFilters>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      const valueNormalized = ["groups", "brands"].includes(key)
        ? value.split(",")
        : value;

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: valueNormalized,
        };
      }
    });

    return filtersValueQueryParams;
  });

  const { data, isLoading, isFetching, refetch } = useRegisterSellers(
    page,
    filters,
    15
  );

  function handleNew() {
    if (admin) {
      history.push("/admin/criar/cadastro-representante");
    } else {
      history.push("/representante/criar/cadastro-representante");
    }
  }
  function handleFilter(filter: SellerFilters) {
    setFilters({
      ...filter,
      brands: !!filter.brands?.length ? filter.brands : undefined,
      groups: !!filter.groups?.length
        ? filter?.groups?.map((f) => Number(f))
        : undefined,
    });
    setPage(1);
    setViewFilter(false);
  }

  function normalizedFilters(value: any, label: string) {
    let normalized = value;

    switch (label) {
      case "active":
        if (Number(value) === 1) {
          normalized = "ATIVO";
        } else {
          normalized = "INATIVO";
        }

        break;
    }

    return normalized;
  }

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      const valueNormalized = ["groups", "brands"].includes(key)
        ? value.split(",")
        : value;

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: valueNormalized,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de cadastro de representante" />

      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={formikFilter.resetForm}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        normalizedFilters={normalizedFilters}
      >
        <GroupInput>
          <Input
            label="Abreviação"
            {...formikFilter.getFieldProps("aliasName")}
          />
          <Input
            label="Nome completo"
            {...formikFilter.getFieldProps("fullName")}
          />
          <Input label="Email" {...formikFilter.getFieldProps("email")} />
        </GroupInput>

        <GroupInput>
          <Select
            label="Situação"
            data={[
              {
                name: "Ativo",
                value: "1",
              },
              {
                name: "Inativo",
                value: "0",
              },
            ]}
            {...formikFilter.getFieldProps("status")}
          />
        </GroupInput>
      </PanelFilter>

      <TableHeader title="REPRESENTANTES" isFetching={isFetching && !isLoading}>
        <button onClick={() => refetch()}>
          <IoReload size={19} />
          <span>RECARREGAR</span>
        </button>

        <button onClick={handleNew}>
          <MdAdd size={19} />
          <span>NOVO</span>
        </button>
      </TableHeader>

      <TableContent<RegisterSeller>
        data={data?.registerSellers}
        columns={columns.filter((f) => f.viewList)}
        isLoading={isLoading}
        onClickRow={(e) => {
          if (admin) {
            history.push(`/admin/cadastro-representante/${e.id}`);
          } else {
            history.push(`/representante/cadastro-representante/${e.id}`);
          }
        }}
      />

      <Pagination
        totalCountOfRegisters={data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
        registersPerPage={15}
      />
    </PanelLayout>
  );
};

export default PanelRegisterSellers;
