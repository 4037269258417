import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import fileSize from "filesize";
import { FaFile } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import {
  ContainerError,
  ContainerPreviews,
  DropContainer,
  Field,
  TemplateFileButton,
} from "./styles";

interface IHeaderProps {
  onFileUploaded: (file: File[]) => void;
  onTrash?: (file: File) => void;
  onTemplateFile?: () => void;
  accept?: string[];
  files?: File[];

  type?: "array" | "one";

  label?: string;
  error?: string;
}

const Dropzone: React.FC<IHeaderProps> = ({
  onFileUploaded,
  onTrash,
  onTemplateFile,
  accept,
  label,
  error,
  files,
  type = "array",
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onFileUploaded(acceptedFiles);
      // acceptedFiles.forEach((element: File) => {
      //   onFileUploaded(element);
      // });
    },
    [onFileUploaded]
  );
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept: accept ? [...accept] : undefined,
    });

  function rederDragMessage() {
    if (!isDragActive) return "Arraste os arquivos aqui...";

    if (isDragReject) return "Arquivo não suportado";

    return "Solte os arquivos aqui";
  }

  function generateAccept(): string {
    var data = "";

    if (accept) {
      for (const item of accept) {
        data += `${item},`;
      }
    }

    return data;
  }

  function styleBorder() {
    if (!isDragActive) return {};

    if (isDragReject) return { borderColor: "#cf1717" };

    return { borderColor: "#20c71a" };
  }

  const isVisibleDropContainer =
    type === "array" ? true : (files?.length ?? 0) < 1;

  return (
    <Field className="field">
      {label && <label>{label}</label>}

      {onTemplateFile && (
        <TemplateFileButton type="button" onClick={onTemplateFile}>
          Download arquivo modelo
          <span>
            <FaFile size={"0.8rem"} color="#333" />
          </span>
        </TemplateFileButton>
      )}

      {isVisibleDropContainer && (
        <DropContainer style={styleBorder()} {...getRootProps()}>
          <input {...getInputProps()} accept={generateAccept()} />
          <span>{rederDragMessage()}</span>
        </DropContainer>
      )}

      <ContainerPreviews
        style={{
          overflowY: "auto",
          maxHeight: "25vh",
        }}
      >
        {files?.map((file) => (
          <li>
            <FaFile size={"1.5rem"} color="#333" />
            <div className="fileInfo">
              <div>
                <strong>{file.name}</strong>
                <span>{fileSize(file.size)}</span>
              </div>

              {onTrash && (
                <button
                  type="button"
                  onClick={() => {
                    onTrash(file);
                  }}
                >
                  <IoIosClose size={30} />
                </button>
              )}
            </div>
          </li>
        ))}
      </ContainerPreviews>

      {!!error && (
        <ContainerError>
          <FiAlertCircle color="#f00" size={16} />
          <span> {error} </span>
        </ContainerError>
      )}
    </Field>
  );
};

export default Dropzone;
