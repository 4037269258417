import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { ButtunSubmit, Form, ScreenLoading } from "../../../create/styles";

import moment from "moment";
import { IForecast } from "..";
import { ButtonCheck } from "../../../../components/Form/ButtonCheck";
import { GroupInput } from "../../../../components/Form/GroupInput";
import { Input } from "../../../../components/Form/Input";
import { Select } from "../../../../components/Form/Select";
import Loading from "../../../../components/Loading";
import {
  Container,
  ContainerForm,
} from "../../../../components/ModalModificationHeaderDatasheet/styled";
import { useSellers } from "../../../../hook/queries/useSeller";
import api from "../../../../service/api";
import { IDescription } from "../../../create/CreateForecast";
import { IBrand } from "../../Devolution";

interface ModalModificationDatasheetProps {
  type: "update";
  onRequestUpdate?: () => void;
  onRequestClose: () => void;
  data: IForecast;
}

export const ModalModificationForecast: React.FC<
  ModalModificationDatasheetProps
> = ({ type, onRequestUpdate, onRequestClose, data }) => {
  const { addToast } = useToasts();
  const getSellers = useSellers(1, { managerOrSupervisor: 1, active: 1 }, 9999);

  const [loading, setLoading] = useState(false);
  const [loadingPage] = useState(false);

  const [descriptions, setDescriptions] = useState<IDescription[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [years, setYears] = useState<
    {
      id: number;
      year: string;
    }[]
  >([]);

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      status: data.status.cod,

      collectionStartDate: data.collectionStartDate,
      collectionEndDate: data.collectionEndDate,

      collection: data.forecast_description?.id?.toString(),
      descriptionYear: data.descriptionYear?.toString(),
      brand: data.brand.cod,
      seller: data.seller.cod,

      historic1: data.historic1,
      historic2: data.historic2,
      historic3: data.historic3,
      historic4: data.historic4,

      isGoals: data.isGoals ? "1" : "0",
    },
    validationSchema: Yup.object({
      collection: Yup.string().required("Obrigatório"),
      descriptionYear: Yup.string().required("Obrigatório"),
      brand: Yup.string().required("Marca é obrigatório"),
      seller: Yup.string().required("Responsável é obrigatório"),

      historic1: Yup.string().required("Historico 1 é obrigatório"),
      historic2: Yup.string().required("Historico 2 é obrigatório"),
      historic3: Yup.string().required("Historico 3 é obrigatório"),
      historic4: Yup.string().required("Historico 4 é obrigatório"),
    }),

    onSubmit: async (data) =>
      type === "update" ? await handleSubmitUpdate(data) : () => {},
  });

  useEffect(() => {
    if (data?.collectionStartDate)
      formik.setFieldValue(
        "collectionStartDate",

        moment(data?.collectionStartDate).add("day", 1).format("YYYY-MM-DD")
      );
    if (data?.collectionEndDate)
      formik.setFieldValue(
        "collectionEndDate",

        moment(data?.collectionEndDate).add("day", 1).format("YYYY-MM-DD")
      );

    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    (async () => {
      const brandDB = await api.get<IBrand[]>("/populate/brand");
      setBrands(brandDB.data);
      const descriptionDB = await api.get<IDescription[]>(
        "/populate/forecast/description"
      );
      setDescriptions(descriptionDB.data);

      let dateYears = [];
      const year = new Date().getFullYear();

      for (let index = -6; index < 5; index++) {
        dateYears.push({ id: index + 1, year: String(year + index) });
      }

      setYears(dateYears);
    })();

    // eslint-disable-next-line
  }, []);

  async function handleSubmitUpdate(props: any) {
    setLoading(true);

    try {
      const updateDatasheet = {
        collectionStartDate: props.collectionStartDate
          ? new Date(props.collectionStartDate)
          : undefined,
        collectionEndDate: props.collectionEndDate
          ? new Date(props.collectionEndDate)
          : undefined,
        collection: props.collection,
        descriptionYear: props.descriptionYear,
        brandCode: props.brand,
        sellerCode: props.seller,

        historic1: props.historic1,
        historic2: props.historic2,
        historic3: props.historic3,
        historic4: props.historic4,

        status: props.status,

        isGoals: props.isGoals === "1",
      };

      await api.put(`/forecast/${data.id}`, updateDatasheet);

      setLoading(false);
      onRequestClose();

      if (onRequestUpdate) onRequestUpdate();

      addToast("Alterações realizada com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      setLoading(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <Form
        onSubmit={
          loading
            ? (e) => {
                e.preventDefault();
              }
            : formik.handleSubmit
        }
      >
        <ContainerForm>
          <div className="containerForm">
            {!loadingPage && !getSellers.isLoading ? (
              <>
                <h3>Situação</h3>

                <Select
                  {...formik.getFieldProps("status")}
                  data={[
                    {
                      name: "Em andamento",
                      value: 1,
                    },
                    {
                      name: "Finalizado",
                      value: 2,
                    },
                    {
                      name: "Cancelado",
                      value: 3,
                    },
                    {
                      name: "Recusado diretor",
                      value: 4,
                    },
                    {
                      name: "Finalizado gerente",
                      value: 5,
                    },
                  ]}
                />

                <h3>Detalhes forecast</h3>
                <>
                  <GroupInput>
                    <Select
                      label="Gerente"
                      {...formik.getFieldProps("seller")}
                      data={[
                        {
                          name: `${data.seller.cod} - ${data.seller.abbreviation}`,
                          value: data.seller.cod,
                        },
                        ...(getSellers.data?.sellers
                          .filter((f) => f.cod !== data.seller.cod)

                          .map((seller) => ({
                            name: `${seller.cod} - ${seller.abbreviation}`,
                            value: seller.cod,
                          })) ?? []),
                      ].sort((a, b) => b.value - a.value)}
                      error={
                        formik.touched.seller && formik.errors.seller
                          ? formik.errors.seller
                          : undefined
                      }
                    />
                    <Select
                      label="Marca"
                      {...formik.getFieldProps("brand")}
                      data={brands.map((item) => ({
                        name: item.name,
                        value: item.cod,
                      }))}
                      error={
                        formik.touched.brand && formik.errors.brand
                          ? formik.errors.brand
                          : undefined
                      }
                    />
                  </GroupInput>

                  <GroupInput>
                    <Select
                      label="Coleção"
                      {...formik.getFieldProps("collection")}
                      data={descriptions.map((item) => ({
                        name: item.description,
                        value: item.id.toString(),
                      }))}
                      error={
                        formik.touched.collection && formik.errors.collection
                          ? formik.errors.collection
                          : undefined
                      }
                    />
                    <Select
                      label="Ano"
                      {...formik.getFieldProps("descriptionYear")}
                      data={years
                        .map((item) => ({
                          name: item.year,
                          value: item.year.toString(),
                        }))
                        .sort((a, b) => Number(a.value) - Number(b.value))}
                      error={
                        formik.touched.descriptionYear &&
                        formik.errors.descriptionYear
                          ? formik.errors.descriptionYear
                          : undefined
                      }
                    />
                  </GroupInput>
                </>

                <h3>Históricos</h3>
                <GroupInput>
                  <Input
                    label="Histórico 1"
                    {...formik.getFieldProps("historic1")}
                    error={
                      formik.touched.historic1 && formik.errors.historic1
                        ? formik.errors.historic1
                        : undefined
                    }
                  />
                  <Input
                    label="Histórico 2"
                    {...formik.getFieldProps("historic2")}
                    error={
                      formik.touched.historic2 && formik.errors.historic2
                        ? formik.errors.historic2
                        : undefined
                    }
                  />
                  <Input
                    label="Histórico 3"
                    {...formik.getFieldProps("historic3")}
                    error={
                      formik.touched.historic3 && formik.errors.historic3
                        ? formik.errors.historic3
                        : undefined
                    }
                  />
                  <Input
                    label="Histórico 4"
                    {...formik.getFieldProps("historic4")}
                    error={
                      formik.touched.historic4 && formik.errors.historic4
                        ? formik.errors.historic4
                        : undefined
                    }
                  />
                </GroupInput>

                <h3>Datas da Coleção</h3>

                <GroupInput>
                  <Input
                    label="Data de Início"
                    type="date"
                    {...formik.getFieldProps("collectionStartDate")}
                    error={
                      formik.touched.collectionStartDate &&
                      formik.errors.collectionStartDate
                        ? formik.errors.collectionStartDate
                        : undefined
                    }
                  />
                  <Input
                    label="Data de Fim"
                    type="date"
                    {...formik.getFieldProps("collectionEndDate")}
                    error={
                      formik.touched.collectionEndDate &&
                      formik.errors.collectionEndDate
                        ? formik.errors.collectionEndDate
                        : undefined
                    }
                  />
                </GroupInput>

                <h3>Metas por representante</h3>
                <ButtonCheck
                  name="isGoals"
                  bgColor="rgb(50, 50, 50)"
                  options={[
                    { field: "Sim", value: "1" },
                    { field: "Não", value: "0" },
                  ]}
                  setValue={(value) => {
                    formik.setFieldValue("isGoals", value);
                  }}
                  value={formik.values.isGoals}
                  error={
                    formik.touched.isGoals && formik.errors.isGoals
                      ? formik.errors.isGoals
                      : ""
                  }
                />
              </>
            ) : (
              <ScreenLoading>
                <Loading
                  colorLoading="#333"
                  size={40}
                  borderColor={"#888"}
                  borderSize={5}
                />
              </ScreenLoading>
            )}
          </div>

          <div className="containerFormButton">
            <ButtunSubmit style={{ margin: 0 }} type="submit">
              {loading ? (
                <div>
                  <Loading borderSize={2} size={20} />
                </div>
              ) : (
                type === "update" && "Alterar Forecast"
              )}
            </ButtunSubmit>
          </div>
        </ContainerForm>
      </Form>
    </Container>
  );
};
