import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FaSync } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { IoMdSearch } from "react-icons/io";
import { useMutation } from "react-query";
import ReactQuill from "react-quill";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { IPermissionUser } from "../../../@types";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { Select } from "../../../components/Form/Select";
import { HistoryAccess } from "../../../components/HistoryAccess";
import Loading from "../../../components/Loading";
import Modal from "../../../components/ModalLib";
import { Tab } from "../../../components/Tab";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { useOneClient } from "../../../hook/queries/useClients";
import { useConcepts } from "../../../hook/queries/useConcepts";
import { useGroupsClient } from "../../../hook/queries/useGroupClient";
import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";
import { getClientByCnpj } from "../../../use-cases/getClientByCnpj";
import { addressAbbreviation } from "../../../utils/addressAbbreviation";
import { setFormikValues } from "../../../utils/setFormikValues";
import { Contact, Contacts } from "../../create/CreateClient/Contacts";
import { BrandProps } from "../../create/CreateShowFile";
import {
  Error as ComponentError,
  ContainerCheckbox,
} from "../../create/styles";
import { PopUpConfirmation } from "../../createAndDetail/styles";
import { ButtunSubmit, ContainerCheck } from "../styles";
import { ClientFiles } from "./clientFiles";

interface HomeProps {
  location: {
    state: {
      client: ClientProps;
    };
  };

  permission?: IPermissionUser;
  admin?: boolean;
  seller?: boolean;
}
interface ClientProps {
  id?: number;
  cod?: number;
  cnpj?: string;
  situation?: string;
  company_name?: string;
  overseer_name?: string;
  id_registration_group?: string;
  trade_name?: string;
  email?: string;
  emailInvoice?: string;
  phone?: string;
  phone2?: string;
  cellPhone?: string;
  suframa?: string;
  stateRegistration?: string;
  registerGroupCod?: string;
  created_at?: Date;
  acess_client?: {
    password?: string;
    firstAccess?: boolean;
  };
  adresses?: {
    zip_code?: string;
    uf?: string;
    city?: string;
    street?: string;
    complement?: string;
    numberStreet?: string;
    neighborhood?: string;
  };
}
interface ClientInternalProps {
  isHasNetwork: boolean;
  isClientContact: boolean;
  qtdStores: string;
  cityPurchase: string;
  isCentralizedDelivery: boolean;
  cnpjToCentralizedDelivery: string;
  isStoreInShopping: boolean;
  managerNamePurchase: string;
  managerPhonePurchase: string;
  managerEmailPurchase: string;
  invoiceEmail: string;
  businessEmail: string;
  segment: string;
  reasonNoContact: string;
}
interface ProductTypeProps {
  id: string;
  name: string;
}
interface ProductTypeDTO {
  contents: ProductTypeProps[];
}
export interface MatchParams {
  id?: string;
}

const Client: React.FC<HomeProps> = ({ permission, admin, seller }) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const match = useRouteMatch<MatchParams>();

  const { data: groupsClient } = useGroupsClient();
  const { data: concepts } = useConcepts();
  const oneClient = useOneClient(Number(match?.params?.id));

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [productTypesDB, setProductTypesDB] = useState<ProductTypeProps[]>([]);
  const [brandsDB, setBrandsDB] = useState<BrandProps[]>([]);
  const [otherBrand] = useState(false);
  const [inputCheck, setInputCheck] = useState<any>({
    brands: {},
    newbrands: {},
    typesProduct: {},
  });

  const [loading, setLoading] = useState(true);
  const [loadingFetchClient, setLoadingFetchClient] = useState(false);
  const [loadingUpdatedClient, setLoadingUpdatedClient] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: "",
      cod: "",
      situation: "",
      id_registration_group: "",
      registerGroupCod: "",
      suframa: "",
      stateRegistration: "",
      conceptCod: "",
      cnpj: "",
      company_name: "",
      trade_name: "",
      overseer_name: "",
      email: "",
      emailInvoice: "",
      phone: "",
      phone2: "",
      cellPhone: "",
      foundationDate: "",
      concept_description: "",
      firstAccess: false,

      adresses: {
        zip_code: "",
        uf: "",
        city: "",
        street: "",
        numberStreet: "",
        neighborhood: "",
        complement: "",
      },
    },
    validationSchema: Yup.object({
      cnpj: Yup.number()
        .typeError("CNPJ é inválido")
        .required("CNPJ é obrigatório"),
      company_name: Yup.string().required("Razão social é obrigatório"),
      trade_name: Yup.string().required("Nome fantasia é obrigatório"),
      email: Yup.string()
        .email("informe um e-mail válido")
        .required("email é obrigatório"),

      adresses: Yup.object().shape({
        zip_code: Yup.string().required("CEP é obrigatório"),
        uf: Yup.string()
          .min(2, "UF é inválida")
          .max(2, "UF é inválida")
          .required("UF é obrigatório"),
        city: Yup.string().required("Cidade é obrigatório"),
        street: Yup.string().required("Logradouro é obrigatório"),
        numberStreet: Yup.number()
          .typeError("Numero é inválido")
          .required("Número é obrigatório"),
      }),
    }),

    onSubmit: (a) => {
      //@ts-ignore
      handleUpdateClient(a);
    },
  });

  const formikAccess = useFormik({
    initialValues: {
      acess_client: {
        password: "",
      },
    },
    validationSchema: Yup.object({
      acess_client: Yup.object().shape({
        password: Yup.string()
          .min(8, "A senha precisa conter no mínimo 8 caracteres.")
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "A senha precisa conter letrar e numeros, no mínimo 8 caracteres e um caracter especial."
          )
          .required("É obrigatório"),
      }),
    }),

    onSubmit: (a) => {
      //@ts-ignore
      handleUpdateAccessClient(a);
    },
  });

  const formikInternal = useFormik({
    initialValues: {
      isHasNetwork: null,
      qtdStores: null,
      cityPurchase: "",
      isCentralizedDelivery: null,
      cnpjToCentralizedDelivery: "",
      isStoreInShopping: null,
      isClientContact: null,
      instagram: "",
      managerNamePurchase: "",
      managerPhonePurchase: "",
      managerEmailPurchase: "",
      internalObservation: "",
      invoiceEmail: "",
      businessEmail: "",
      segment: "",
      otherBrandName: "",
      reasonNoContact: "",
    },
    validationSchema: Yup.object({
      qtdStores: Yup.number()
        .nullable()
        .test(
          "validOtherBrand",
          `E necessário informar Quantidade de lojas que possui`,
          function (value) {
            let error = true;

            if (formikInternal.values.isHasNetwork && !value) {
              error = false;
            }

            return error;
          }
        ),
      managerEmailPurchase: Yup.string().email("Email não é válido"),
      invoiceEmail: Yup.string().email("Email não é válido"),
      businessEmail: Yup.string().email("Email não é válido"),
      otherBrandName: Yup.string()
        .nullable()
        .test(
          "validOtherBrand",
          `E necessário informar nome da "OUTRA" marca`,
          function (value) {
            let error = true;

            if (otherBrand && !value) {
              error = false;
            }

            return error;
          }
        ),
      cnpjToCentralizedDelivery: Yup.string()
        .nullable()
        .test(
          "validOtherBrand",
          `E necessário informar CNPJ da entrega centralizada`,
          function (value) {
            let error = true;

            if (formikInternal.values.isCentralizedDelivery && !value) {
              error = false;
            }

            return error;
          }
        ),
      cityPurchase: Yup.string()
        .nullable()
        .test(
          "validOtherBrand",
          `E necessário informar Cidade/Estado da central de compras`,
          function (value) {
            let error = true;

            if (formikInternal.values.isHasNetwork && !value) {
              error = false;
            }

            return error;
          }
        ),
    }),

    onSubmit: (a) => {
      //@ts-ignore
      handleUpdateClientInternal(a);
    },
  });

  function getListValues(data: any) {
    if (data) {
      const keys = Object.keys(data);
      const values = Object.values(data);

      return keys
        .map((key, index) => (!!values[index] ? key : undefined))
        .filter((f) => f);
    }

    return [];
  }

  function setChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    setInputCheck({
      ...inputCheck,
      [e.target.name]: {
        ...inputCheck[e.target.name],
        [e.target.value]: e.target.checked,
      },
    });
  }

  async function handleFetchClient(cnpj: string) {
    setLoadingFetchClient(true);
    try {
      const client = await getClientByCnpj(cnpj);

      if (!client.cnpj) {
        addToast("Ocorreu um erro ao buscar dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      formik.setFieldValue(
        "company_name",
        addressAbbreviation(client.razao_social)
      );
      formik.setFieldValue(
        "foundationDate",
        addressAbbreviation(client.nome_fantasia)
      );
      formik.setFieldValue(
        "adress.neighborhood",
        addressAbbreviation(client.bairro)
      );
      formik.setFieldValue(
        "adress.street",
        addressAbbreviation(client.logradouro)
      );
      formik.setFieldValue(
        "adress.street",
        addressAbbreviation(client.logradouro)
      );

      formik.setFieldValue(
        "foundationDate",
        new Date(client.data_inicio_atividade)
      );

      formik.setFieldValue("adress.zip_code", client.cep);
      formik.setFieldValue("adress.uf", client.uf);
      formik.setFieldValue("adress.city", client.municipio);
      formik.setFieldValue("adress.numberStreet", client.numero);
      formik.setFieldValue("adress.complement", client.complemento);
      formik.setFieldValue("suframa", client.suframa);
      formik.setFieldValue("stateRegistration", client.inscricao_estadual);
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um problema ao consultar os dados, Tente novamente mais tarde",
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
    } finally {
      setLoadingFetchClient(false);
    }
  }

  async function handleUpdateAccessClient(updates: ClientProps) {
    try {
      await updateClient.mutateAsync(updates);
      addToast("Cliente encaminhado para alterado.", {
        appearance: "success",
        autoDismiss: true,
      });
      history.goBack();
    } catch (err) {
      const error = err as any;
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleUpdateClient(update: ClientProps) {
    setLoadingUpdatedClient(true);

    try {
      const updateClient = {
        cnpj: update.cnpj,
        company_name: update.company_name,
        trade_name: update.trade_name,
        overseer_name: update.overseer_name,
        email: update.email,
        phone: update.phone,
        phone2: update.phone2,
        cellPhone: update.cellPhone,
        emailAccountant: update.email,
        emailInvoice: update.emailInvoice,
        suframa: update.suframa,
        stateRegistration: update.stateRegistration,
        registerGroupCod: update.registerGroupCod,

        adress: {
          zip_code: update.adresses?.zip_code,
          uf: update.adresses?.uf,
          city: update.adresses?.city,
          neighborhood: update.adresses?.neighborhood,
          street: update.adresses?.street,
          numberStreet: update.adresses?.numberStreet,
          complement: update.adresses?.complement,
        },
      };

      await api.post("/request-update-client/register/", {
        client: updateClient,
        contacts,
      });

      addToast("Cliente encaminhado para setor de alteração com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (err) {
      const error = err as any;
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoadingUpdatedClient(false);
    }
  }

  async function handleUpdateClientInternal(updates: ClientInternalProps) {
    try {
      setLoadingUpdatedClient(true);

      await updateClientInternal.mutateAsync({
        ...updates,
        reasonNoContact:
          updates.isClientContact === false ? updates.reasonNoContact : "",
      });

      addToast("Cliente alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      setLoadingUpdatedClient(false);
      // history.goBack();
    } catch (err) {
      const error = err as any;
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  const updateClient = useMutation(
    async (updates: ClientProps) => {
      const response = await api.put(`/clientPJ/${match.params.id}`, updates);

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Clients");
        queryClient.invalidateQueries(["Client", match.params.id]);

        oneClient?.refetch();
      },
    }
  );
  const updateClientInternal = useMutation(
    async (updates: ClientInternalProps) => {
      const response = await api.put(`/clientPJ/${oneClient?.data?.id}`, {
        ...updates,
        brands: getListValues(inputCheck.brands),
        typesProduct: getListValues(inputCheck.typesProduct),
        newbrands: getListValues(inputCheck.newbrands),
        internalDataClient: true,
      });

      return response.data;
    },
    {
      onSuccess: (client: ClientProps) => {
        queryClient.invalidateQueries("Clients");
        queryClient.invalidateQueries(["Client", client.id]);

        oneClient?.refetch();
      },
    }
  );

  useEffect(() => {
    (async () => {
      const brandDB = await api.get<BrandProps[]>("/populate/brand", {
        params: { active: 1, is_not_working: 1 },
      });
      setBrandsDB(brandDB.data);

      const productTypesDB = await api.get<ProductTypeDTO>(
        "/populate/product_type"
      );
      setProductTypesDB(productTypesDB.data.contents);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (seller) {
        if (oneClient?.data?.cnpj) {
          await handleFetchClient(oneClient?.data?.cnpj);
        }
      }
    })();

    // eslint-disable-next-line
  }, [oneClient?.data?.cnpj]);

  useEffect(() => {
    (async () => {
      if (oneClient?.isSuccess && oneClient?.data) {
        let inputCheckData = inputCheck;

        oneClient?.data.productTypes.forEach((p) => {
          inputCheckData = {
            ...inputCheckData,
            typesProduct: { ...inputCheckData.typesProduct, [p.id]: true },
          };
        });
        oneClient?.data.brands.forEach((b) => {
          inputCheckData = {
            ...inputCheckData,
            brands: { ...inputCheckData.brands, [b.id]: true },
          };
        });

        oneClient?.data?.newbrands?.forEach((b: any) => {
          inputCheckData = {
            ...inputCheckData,
            newbrands: { ...inputCheckData.newbrands, [b.id]: true },
          };
        });

        setInputCheck(inputCheckData);

        setFormikValues(oneClient?.data, formik, setLoading);

        if (oneClient.data?.concept?.cod) {
          formik.setFieldValue("conceptCod", oneClient.data?.concept?.cod);
        }

        if (oneClient.data?.registerGroup?.cod) {
          formik.setFieldValue(
            "registerGroupCod",
            oneClient.data?.registerGroup.cod
          );
        }

        setFormikValues(oneClient?.data, formikInternal, setLoading);
      } else {
        setLoading(true);
      }
    })();

    // eslint-disable-next-line
  }, [oneClient?.isSuccess]);

  return (
    <CreateAndDetailLayout isLoading={oneClient?.isLoading || loading}>
      <PanelAndDetailAndCreateHeader title={"Cliente"} goBack />

      <Tab
        tabs={[
          {
            description: "Cadastro",
            component: (
              <LayoutForm onSubmit={formik.handleSubmit}>
                <Select
                  disabled={seller}
                  label="Ativo"
                  data={[
                    { name: "Ativo", value: "1" },
                    { name: "Inativo", value: "0" },
                  ]}
                  {...formik.getFieldProps("situation")}
                  readOnly
                />

                <Input
                  readOnly
                  label="Código"
                  {...formik.getFieldProps("cod")}
                  error={
                    formik.touched.cod && formik.errors.cod
                      ? formik.errors.cod
                      : undefined
                  }
                />

                {seller ? (
                  <Input
                    readOnly
                    label="CNPJ"
                    {...formik.getFieldProps("cnpj")}
                  />
                ) : (
                  <div className="field">
                    <label htmlFor="cnpj">CNPJ</label>

                    <div className="containerCnpj">
                      <input
                        type="text"
                        maxLength={14}
                        {...formik.getFieldProps("cnpj")}
                        placeholder="Exemplo CNPJ: 00000000000000"
                        readOnly
                        style={{ opacity: 0.5 }}
                      />

                      <button
                        type="button"
                        onClick={() => {
                          handleFetchClient(formik.values.cnpj);
                        }}
                      >
                        {loadingFetchClient ? (
                          <Loading
                            size={22}
                            borderSize={3.5}
                            colorLoading="rgba(255,255,255)"
                            borderColor="rgba(255,255,255, 0.3)"
                          />
                        ) : (
                          <>
                            <IoMdSearch />
                            Buscar
                          </>
                        )}
                      </button>
                    </div>

                    {formik.touched.cnpj && formik.errors.cnpj ? (
                      <ComponentError>
                        <FiAlertCircle color="#f00" size={16} />
                        <span> {formik.errors.cnpj} </span>
                      </ComponentError>
                    ) : null}
                  </div>
                )}

                <GroupInput>
                  <Input
                    readOnly={seller}
                    label="Razão social"
                    {...formik.getFieldProps("company_name")}
                    error={
                      formik.touched.company_name && formik.errors.company_name
                        ? formik.errors.company_name
                        : undefined
                    }
                  />

                  <Input
                    readOnly={seller}
                    label="Nome fantasia"
                    {...formik.getFieldProps("trade_name")}
                    error={
                      formik.touched.trade_name && formik.errors.trade_name
                        ? formik.errors.trade_name
                        : undefined
                    }
                  />
                </GroupInput>

                <Input
                  label="Encarregado"
                  {...formik.getFieldProps("overseer_name")}
                  error={
                    formik.touched.overseer_name && formik.errors.overseer_name
                      ? formik.errors.overseer_name
                      : undefined
                  }
                />

                <GroupInput>
                  <Input
                    label="Suframa"
                    {...formik.getFieldProps("suframa")}
                    error={
                      formik.touched.suframa && formik.errors.suframa
                        ? formik.errors.suframa
                        : undefined
                    }
                  />
                  <Input
                    label="Inscrição estadual"
                    {...formik.getFieldProps("stateRegistration")}
                    error={
                      formik.touched.stateRegistration &&
                      formik.errors.stateRegistration
                        ? formik.errors.stateRegistration
                        : undefined
                    }
                  />
                </GroupInput>

                <GroupInput>
                  <Select
                    label="Grupo de cadastro"
                    disabled
                    data={
                      groupsClient?.map((group) => ({
                        value: group.cod,
                        name: group.name,
                      })) ?? []
                    }
                    {...formik.getFieldProps("registerGroupCod")}
                    error={
                      formik.touched.registerGroupCod &&
                      formik.errors.registerGroupCod
                        ? formik.errors.registerGroupCod
                        : undefined
                    }
                  />

                  <Select
                    label="Conceito"
                    disabled
                    data={
                      concepts?.map((group) => ({
                        value: group.cod,
                        name: `${group.cod} ${group.description}`,
                      })) ?? []
                    }
                    {...formik.getFieldProps("conceptCod")}
                    error={
                      formik.touched.conceptCod && formik.errors.conceptCod
                        ? formik.errors.conceptCod
                        : undefined
                    }
                  />
                </GroupInput>

                <GroupInput>
                  <Input
                    label="Email"
                    {...formik.getFieldProps("email")}
                    error={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : undefined
                    }
                  />

                  <Input
                    label="Email NFE"
                    {...formik.getFieldProps("emailInvoice")}
                    error={
                      formik.touched.emailInvoice && formik.errors.emailInvoice
                        ? formik.errors.emailInvoice
                        : undefined
                    }
                  />
                </GroupInput>

                <GroupInput>
                  <Input
                    label="Telefone"
                    maxLength={11}
                    {...formik.getFieldProps("phone")}
                    error={
                      formik.touched.phone && formik.errors.phone
                        ? formik.errors.phone
                        : undefined
                    }
                  />

                  <Input
                    label="Telefone 2"
                    maxLength={11}
                    {...formik.getFieldProps("phone2")}
                    error={
                      formik.touched.phone2 && formik.errors.phone2
                        ? formik.errors.phone2
                        : undefined
                    }
                  />
                </GroupInput>

                <Input
                  label="Celular"
                  maxLength={11}
                  {...formik.getFieldProps("cellPhone")}
                  error={
                    formik.touched.cellPhone && formik.errors.cellPhone
                      ? formik.errors.cellPhone
                      : undefined
                  }
                />

                <h3>Endereço</h3>

                <Input
                  readOnly={seller}
                  label="CEP"
                  {...formik.getFieldProps("adresses.zip_code")}
                  error={
                    formik.touched.adresses?.zip_code &&
                    formik.errors.adresses?.zip_code
                      ? formik.errors.adresses?.zip_code
                      : undefined
                  }
                />

                <GroupInput>
                  <Input
                    readOnly={seller}
                    label="UF"
                    {...formik.getFieldProps("adresses.uf")}
                    error={
                      formik.touched.adresses?.uf && formik.errors.adresses?.uf
                        ? formik.errors.adresses?.uf
                        : undefined
                    }
                  />
                  <Input
                    readOnly={seller}
                    label="Cidade"
                    {...formik.getFieldProps("adresses.city")}
                    error={
                      formik.touched.adresses?.city &&
                      formik.errors.adresses?.city
                        ? formik.errors.adresses?.city
                        : undefined
                    }
                  />
                </GroupInput>

                <GroupInput>
                  <Input
                    readOnly={seller}
                    label="Bairro"
                    {...formik.getFieldProps("adresses.neighborhood")}
                    error={
                      formik.touched.adresses?.neighborhood &&
                      formik.errors.adresses?.neighborhood
                        ? formik.errors.adresses?.neighborhood
                        : undefined
                    }
                  />
                  <Input
                    readOnly={seller}
                    label="Logradouro"
                    {...formik.getFieldProps("adresses.street")}
                    error={
                      formik.touched.adresses?.street &&
                      formik.errors.adresses?.street
                        ? formik.errors.adresses?.street
                        : undefined
                    }
                  />
                </GroupInput>
                <GroupInput>
                  <Input
                    readOnly={seller}
                    label="Número"
                    {...formik.getFieldProps("adresses.numberStreet")}
                    error={
                      formik.touched.adresses?.numberStreet &&
                      formik.errors.adresses?.numberStreet
                        ? formik.errors.adresses?.numberStreet
                        : undefined
                    }
                  />
                  <Input
                    label="Complemento"
                    {...formik.getFieldProps("adresses.complement")}
                    error={
                      formik.touched.adresses?.complement &&
                      formik.errors.adresses?.complement
                        ? formik.errors.adresses?.complement
                        : undefined
                    }
                  />
                </GroupInput>

                <Contacts contacts={contacts} setContacts={setContacts} />

                {((permission && permission?.clientPJ > 2) || seller) && (
                  <ButtunSubmit type="submit" disabled={loadingUpdatedClient}>
                    {loadingUpdatedClient ? (
                      <Loading
                        size={22}
                        borderSize={3.5}
                        colorLoading="rgba(255,255,255)"
                        borderColor="rgba(255,255,255, 0.3)"
                      />
                    ) : (
                      "Alterar dados"
                    )}{" "}
                  </ButtunSubmit>
                )}
              </LayoutForm>
            ),
          },
          {
            description: "Acessos",
            component: (
              <>
                {admin && (
                  <LayoutForm onSubmit={formikAccess.handleSubmit}>
                    <h3>Alterar a senha</h3>
                    <Input
                      label="Nova senha"
                      {...formikAccess.getFieldProps("acess_client.password")}
                      error={
                        formikAccess.touched.acess_client?.password &&
                        formikAccess.errors.acess_client?.password
                          ? formikAccess.errors.acess_client?.password
                          : undefined
                      }
                    />

                    {permission && permission?.clientPJ > 2 && (
                      <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
                    )}
                  </LayoutForm>
                )}

                <HistoryAccess
                  data={
                    oneClient?.data
                      ? oneClient.data.access.map((acces) => ({
                          id: String(oneClient.data.id),
                          cod: String(oneClient.data.cod),
                          name: oneClient?.data?.trade_name ?? "",
                          date: acces.date,
                          dateToString: acces.dateToString,
                        }))
                      : []
                  }
                />
              </>
            ),
          },
          {
            description: "Dados internos",
            component: (
              <>
                <LayoutForm
                  onSubmit={
                    permission && permission?.clientPJ > 2
                      ? formikInternal.handleSubmit
                      : undefined
                  }
                >
                  <Input
                    label="Data da última compra"
                    name="lastOrderDate"
                    value={oneClient?.data?.lastOrderDateToString}
                    readOnly
                  />

                  <div className="field">
                    <label>Realizou contato com cliente com exito</label>
                    <ContainerCheck>
                      <button
                        type="button"
                        className={
                          formikInternal.values.isClientContact === true
                            ? "check"
                            : ""
                        }
                        onClick={() =>
                          formikInternal.setFieldValue("isClientContact", true)
                        }
                      >
                        SIM
                      </button>

                      <button
                        type="button"
                        onClick={() =>
                          formikInternal.setFieldValue("isClientContact", false)
                        }
                        className={
                          formikInternal.values.isClientContact === false
                            ? "check"
                            : ""
                        }
                      >
                        NÃO
                      </button>
                    </ContainerCheck>
                  </div>

                  {formikInternal.values.isClientContact === false && (
                    <Select
                      label="Motivo da falha no contato"
                      data={[
                        {
                          name: "Não tem interesse na atualização",
                          value: "Não tem interesse na atualização",
                        },
                        {
                          name: "Telefone incorreto",
                          value: "Telefone incorreto",
                        },
                        { name: "E-mail enviado", value: "E-mail enviado" },
                      ]}
                      {...formikInternal.getFieldProps("reasonNoContact")}
                      error={
                        formikInternal.touched.reasonNoContact &&
                        formikInternal.errors.reasonNoContact
                          ? formikInternal.errors.reasonNoContact
                          : undefined
                      }
                    />
                  )}

                  <div className="field">
                    <label>Cliente possui rede (mais de uma loja)</label>
                    <ContainerCheck>
                      <button
                        type="button"
                        className={
                          formikInternal.values.isHasNetwork === true
                            ? "check"
                            : ""
                        }
                        onClick={() =>
                          formikInternal.setFieldValue("isHasNetwork", true)
                        }
                      >
                        SIM
                      </button>

                      <button
                        type="button"
                        onClick={() =>
                          formikInternal.setFieldValue("isHasNetwork", false)
                        }
                        className={
                          formikInternal.values.isHasNetwork === false
                            ? "check"
                            : ""
                        }
                      >
                        NÃO
                      </button>
                    </ContainerCheck>
                  </div>

                  {formikInternal.values.isHasNetwork && (
                    <>
                      <GroupInput>
                        <Input
                          type="number"
                          label="Quantidade de lojas que possui"
                          {...formikInternal.getFieldProps("qtdStores")}
                          error={
                            formikInternal.touched.qtdStores &&
                            formikInternal.errors.qtdStores
                              ? formikInternal.errors.qtdStores
                              : undefined
                          }
                        />
                        <Input
                          label="Cidade/Estado da central de compras"
                          {...formikInternal.getFieldProps("cityPurchase")}
                          error={
                            formikInternal.touched.cityPurchase &&
                            formikInternal.errors.cityPurchase
                              ? formikInternal.errors.cityPurchase
                              : undefined
                          }
                        />
                      </GroupInput>
                      <div className="field">
                        <label>Entrega centralizada</label>
                        <ContainerCheck>
                          <button
                            type="button"
                            className={
                              formikInternal.values.isCentralizedDelivery ===
                              true
                                ? "check"
                                : ""
                            }
                            onClick={() =>
                              formikInternal.setFieldValue(
                                "isCentralizedDelivery",
                                true
                              )
                            }
                          >
                            SIM
                          </button>

                          <button
                            type="button"
                            onClick={() =>
                              formikInternal.setFieldValue(
                                "isCentralizedDelivery",
                                false
                              )
                            }
                            className={
                              formikInternal.values.isCentralizedDelivery ===
                              false
                                ? "check"
                                : ""
                            }
                          >
                            NÃO
                          </button>
                        </ContainerCheck>
                      </div>

                      {formikInternal.values.isCentralizedDelivery && (
                        <Input
                          label="Qual CNPJ da entrega centralizada"
                          {...formikInternal.getFieldProps(
                            "cnpjToCentralizedDelivery"
                          )}
                          error={
                            formikInternal.touched.cnpjToCentralizedDelivery &&
                            formikInternal.errors.cnpjToCentralizedDelivery
                              ? formikInternal.errors.cnpjToCentralizedDelivery
                              : undefined
                          }
                        />
                      )}
                    </>
                  )}

                  <GroupInput>
                    <Input
                      label="Nome gerente da compra/comprador"
                      {...formikInternal.getFieldProps("managerNamePurchase")}
                      error={
                        formikInternal.touched.managerNamePurchase &&
                        formikInternal.errors.managerNamePurchase
                          ? formikInternal.errors.managerNamePurchase
                          : undefined
                      }
                    />
                    <Input
                      label="Telefone gerente da compra/comprador"
                      {...formikInternal.getFieldProps("managerPhonePurchase")}
                      error={
                        formikInternal.touched.managerPhonePurchase &&
                        formikInternal.errors.managerPhonePurchase
                          ? formikInternal.errors.managerPhonePurchase
                          : undefined
                      }
                    />
                  </GroupInput>

                  <div className="field">
                    <label>Loja em shopping</label>
                    <ContainerCheck>
                      <button
                        type="button"
                        className={
                          formikInternal.values.isStoreInShopping === true
                            ? "check"
                            : ""
                        }
                        onClick={() =>
                          formikInternal.setFieldValue(
                            "isStoreInShopping",
                            true
                          )
                        }
                      >
                        SIM
                      </button>

                      <button
                        type="button"
                        onClick={() =>
                          formikInternal.setFieldValue(
                            "isStoreInShopping",
                            false
                          )
                        }
                        className={
                          formikInternal.values.isStoreInShopping === false
                            ? "check"
                            : ""
                        }
                      >
                        NÃO
                      </button>
                    </ContainerCheck>
                  </div>

                  <GroupInput>
                    <Input
                      label="E-mail gerente de compra/comprador"
                      {...formikInternal.getFieldProps("managerEmailPurchase")}
                      error={
                        formikInternal.touched.managerEmailPurchase &&
                        formikInternal.errors.managerEmailPurchase
                          ? formikInternal.errors.managerEmailPurchase
                          : undefined
                      }
                    />
                    <Input
                      label="E-mail NF"
                      {...formikInternal.getFieldProps("invoiceEmail")}
                      error={
                        formikInternal.touched.invoiceEmail &&
                        formikInternal.errors.invoiceEmail
                          ? formikInternal.errors.invoiceEmail
                          : undefined
                      }
                    />
                    <Input
                      label="E-mail Comercial"
                      {...formikInternal.getFieldProps("businessEmail")}
                      error={
                        formikInternal.touched.businessEmail &&
                        formikInternal.errors.businessEmail
                          ? formikInternal.errors.businessEmail
                          : undefined
                      }
                    />
                  </GroupInput>

                  <Input
                    label="Instagram"
                    {...formikInternal.getFieldProps("instagram")}
                    error={
                      formikInternal.touched.instagram &&
                      formikInternal.errors.instagram
                        ? formikInternal.errors.instagram
                        : undefined
                    }
                  />

                  <GroupInput>
                    <div className="field">
                      <ContainerCheckbox>
                        <label className="titleCheckbox" htmlFor="">
                          Marca
                        </label>
                        <div className="contentsCheckbox">
                          {brandsDB.map((element, index) => (
                            <div key={index} className="boxCheckbox">
                              <input
                                type="checkbox"
                                onChange={setChangeCheckbox}
                                checked={
                                  inputCheck.brands[element.id] ? true : false
                                }
                                value={element.id}
                                name={`brands`}
                              />
                              <label>{element.name}</label>
                            </div>
                          ))}

                          {/* <div className="boxCheckbox">
                            <input
                              type="checkbox"
                              onChange={(a) => setOtherBrand(a.target.checked)}
                              checked={otherBrand}
                            />
                            <label>OUTRA</label>
                          </div> */}
                        </div>
                      </ContainerCheckbox>

                      {otherBrand && (
                        <>
                          <input
                            style={{ marginTop: 10 }}
                            placeholder="Nome da marca (OUTRA MARCA)"
                            {...formikInternal.getFieldProps("otherBrandName")}
                          />

                          {formikInternal.touched.otherBrandName &&
                            formikInternal.errors.otherBrandName && (
                              <ComponentError>
                                <FiAlertCircle color="#f00" size={16} />
                                <span>
                                  {formikInternal.errors.otherBrandName}
                                </span>
                              </ComponentError>
                            )}
                        </>
                      )}
                    </div>

                    <div className="field">
                      <ContainerCheckbox>
                        <label className="titleCheckbox" htmlFor="">
                          Deseja trabalhar com nossas marcas:
                        </label>
                        <div className="contentsCheckbox">
                          {brandsDB
                            .filter((f) => f.is_not_working === false)
                            .map((element, index) => (
                              <div key={index} className="boxCheckbox">
                                <input
                                  type="checkbox"
                                  onChange={setChangeCheckbox}
                                  checked={
                                    inputCheck.newbrands[element.id]
                                      ? true
                                      : false
                                  }
                                  value={element.id}
                                  name={`newbrands`}
                                />
                                <label>{element.name}</label>
                              </div>
                            ))}
                        </div>
                      </ContainerCheckbox>
                    </div>

                    <div className="field">
                      <ContainerCheckbox>
                        <label className="titleCheckbox" htmlFor="">
                          Tipo de produto
                        </label>
                        <div className="contentsCheckbox">
                          {productTypesDB.map((element) => (
                            <div key={element.id} className="boxCheckbox">
                              <input
                                type="checkbox"
                                onChange={setChangeCheckbox}
                                checked={
                                  inputCheck.typesProduct[element.id]
                                    ? true
                                    : false
                                }
                                value={element.id}
                                name={`typesProduct`}
                              />
                              <label>{element.name}</label>
                            </div>
                          ))}
                        </div>
                      </ContainerCheckbox>
                    </div>
                  </GroupInput>

                  <GroupInput>
                    <Select
                      {...formikInternal.getFieldProps("segment")}
                      error={
                        formikInternal.touched.segment &&
                        formikInternal.errors.segment
                          ? formikInternal.errors.segment
                          : undefined
                      }
                      data={[
                        { name: "Sapataria", value: "Sapataria" },
                        { name: "Sneaker", value: "Sneaker" },
                        { name: "Boutique", value: "Boutique" },
                        { name: "Moda masculina", value: "Moda Masculina" },
                        { name: "Moda feminina", value: "Moda feminina" },
                        { name: "Moda ambos", value: "Moda ambos" },
                        {
                          name: "Especializado surf/skate",
                          value: "Especializado surf/skate",
                        },
                        {
                          name: "Especializado tennis/paddle/esportes com raquete",
                          value:
                            "Especializado tennis/paddle/esportes com raquete",
                        },
                        {
                          name: "Especializado loja infantil",
                          value: "Especializado loja infantil",
                        },
                        {
                          name: "Especializado loja futebol",
                          value: "Especializado loja futebol",
                        },
                        {
                          name: "Especializado running/crossfit/etc",
                          value: "Especializado running/crossfit/etc",
                        },
                        {
                          name: "Especializado chinelaria",
                          value: "Especializado chinelaria",
                        },
                      ]}
                      label="Segmento"
                    />
                  </GroupInput>

                  <div className="field">
                    <label>Observações internas</label>
                    <ReactQuill
                      theme="snow"
                      onChange={(e) => {
                        formikInternal.setFieldValue(
                          "internalObservation",
                          String(e)
                        );
                        formikInternal.handleChange("internalObservation");
                      }}
                      value={formikInternal.values.internalObservation}
                    />
                  </div>

                  {permission &&
                    permission?.clientPJ > 2 &&
                    (loadingUpdatedClient ? (
                      <ButtunSubmit type="button">
                        <Loading />
                      </ButtunSubmit>
                    ) : (
                      <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
                    ))}
                </LayoutForm>

                <HistoryAccess
                  title="Histórico de alteração dados internos"
                  isNameOnList
                  data={
                    oneClient?.data
                      ? oneClient.data.logUpdateInternalData.map((acces) => ({
                          id: acces.id,
                          cod: acces.id,
                          name: `${acces.user.name} ${
                            acces.user.lastname || ""
                          }`,
                          date: acces.created_at,
                          dateToString: acces.created_atToString,
                        }))
                      : []
                  }
                />
              </>
            ),
          },
          {
            description: "Fotos",
            component: (
              <>
                {oneClient?.data?.cod && (
                  <ClientFiles
                    clientCod={String(oneClient.data.cod)}
                    isOnlyRead={
                      admin && permission && permission?.clientPJ <= 2
                    }
                    isOnlySend={!!seller}
                  />
                )}
              </>
            ),
          },
        ].filter((f) =>
          admin ? f.description : f.description !== "Dados internos"
        )}
      />

      {
        <Modal
          title="Selecione o cliente"
          modalVisible={loadingFetchClient}
          setModalVisible={() => {}}
          headerOff={true}
          maxHeight={250}
          maxWidth={650}
        >
          <PopUpConfirmation>
            <div className="headerPopUp">
              <nav style={{ animation: "spin 0.7s linear infinite" }}>
                <FaSync size={95} opacity={0.2} />
              </nav>
              <span>Buscando dados do cliente...</span>
            </div>
          </PopUpConfirmation>
        </Modal>
      }
    </CreateAndDetailLayout>
  );
};

export default Client;
