import React, { useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { ContainerCheck, Error as ContainerError } from "./styles";

interface Option {
  field: string;
  value: string | boolean;
  color?: string;
}

interface ButtonCheckProps {
  value?: string | boolean;
  setValue: (option: string | boolean) => void;

  name: string;
  options: Option[];

  label?: string;
  error?: string;

  bgColor?: string;
}

export const ButtonCheck: React.FC<ButtonCheckProps> = ({
  value,
  setValue,
  name,
  options,
  error,
  label,
  bgColor: bgColorDefault = "#d21e26",
}) => {
  const [bgColor, setBgColor] = useState(bgColorDefault);

  return (
    <div className="field">
      {label && <label htmlFor={name}>{label}</label>}

      <ContainerCheck style={{ borderColor: bgColor }}>
        {options.map((option, index) => (
          <button
            key={index + option.field}
            type="button"
            className={value === option.value ? "check" : ""}
            onClick={() => {
              if (option.color) {
                setBgColor(option.color);
              }
              setValue(option.value);
            }}
            style={{
              borderColor: bgColor,
              background: value === option.value ? bgColor : undefined,
            }}
          >
            {option.field}
          </button>
        ))}
      </ContainerCheck>

      {!!error && (
        <ContainerError>
          <FiAlertCircle color="#f00" size={16} />
          <span> {error} </span>
        </ContainerError>
      )}
    </div>
  );
};
