import * as ExcelJS from "exceljs";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import Loading from "../../../components/loadings/Loading";
import { DivUploadFile, Error, HeaderUploadFile } from "../styles";

import filesize from "filesize";
import Dropzone from "../../../components/Upload/DropzoneXlsx";
import generateHash from "../../../utils/generateHash";

import DropzoneForm from "../../../components/Form/Dropzone";

import { IoMdTrash } from "react-icons/io";
import logoExecel from "../../../assets/logo-execel.svg";
import { ContainerDropZone } from "../../detail/styles";
import { ContainerPreviews } from "../../import/styles";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { useFormik } from "formik";
import { FiAlertCircle } from "react-icons/fi";
import * as Yup from "yup";

import { MdArrowDownward } from "react-icons/md";
import { RiFileExcel2Line } from "react-icons/ri";

import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { ButtonCheck } from "../../../components/Form/ButtonCheck";
import { ButtonSubmit } from "../../../components/Form/ButtonSubmit";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { Select } from "../../../components/Form/Select";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { useSellers } from "../../../hook/queries/useSeller";
import api from "../../../service/api";

interface IHeaderProps {
  admin: boolean;
}

interface ISeller {
  id: number;
  cod: number;
  abbreviation: string;
  fullName: string;
  email: string;
  phone: string | null;
  is_active: boolean;
  is_manager: boolean;
  is_supervisor: boolean;
  cod_manager: number | null;
  cod_supervisor: number | null;
  created_at: Date;
  updated_at: Date;
}

interface IResponseAPI {
  contents: ISeller[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IBrand {
  id: number;
  cod: number;
  name: string;
  is_active: boolean;
}

export interface IDescription {
  id: number;
  description: string;
  is_active: boolean;
}

const CreateForecast: React.FC<IHeaderProps> = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [years, setYears] = useState<
    {
      id: number;
      year: string;
    }[]
  >([]);

  const [selectFile, setSelectFile] = useState<File>();
  const [files, setFiles] = useState<File | undefined>();

  const [selectFileHistoric, setSelectFileHistoric] = useState<File>();
  const [filesHistoric, setFilesHistoric] = useState<File | undefined>();

  const [fileError, setFileError] = useState("");
  const [fileErrorHistoric, setFileErrorHistoric] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [descriptions, setDescriptions] = useState<IDescription[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const sellers = useSellers(1, { active: 1, managerOrSupervisor: 1 }, 9999);

  const formik = useFormik({
    initialValues: {
      description: "",
      descriptionYear: "",
      title: "",
      brand: "",
      seller: "",
      historic1: "",
      historic2: "",
      historic3: "",
      historic4: "",
      equipment: 1,
      footwear: 1,
      apparel: 1,
      closing_at: undefined,
      isGoals: undefined,
      goalsFile: undefined,
    },
    validationSchema: Yup.object({
      description: Yup.string().required("Obrigatório"),
      descriptionYear: Yup.string().required("Obrigatório"),

      historic1: Yup.string().required("Obrigatório"),
      historic2: Yup.string().required("Obrigatório"),
      historic3: Yup.string().required("Obrigatório"),
      historic4: Yup.string().required("Obrigatório"),

      brand: Yup.string().required("Obrigatório"),
      seller: Yup.string().required("Obrigatório"),
      closing_at: Yup.date().required("Obrigatório"),

      isGoals: Yup.string().required("Obrigatório"),
      goalsFile: Yup.mixed().when("isGoals", {
        is: "1",
        then: Yup.mixed().required("Obrigatório"),
        otherwise: Yup.mixed().notRequired(),
      }),
    }),
    onSubmit: (data) => handleSubmit(data),
  });

  useEffect(() => {
    let dateYears = [];
    const year = new Date().getFullYear();

    for (let index = 0; index < 5; index++) {
      dateYears.push({ id: index + 1, year: String(year + index) });
    }

    setYears(dateYears);
  }, []);

  useEffect(() => {
    if (selectFileHistoric) {
      setFileErrorHistoric("");
      setFilesHistoric(selectFileHistoric);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFileHistoric]);

  useEffect(() => {
    if (selectFile) {
      setFileError("");
      setFiles(selectFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFile]);

  useEffect(() => {
    (async () => {
      const brandDB = await api.get<IBrand[]>("/populate/brand", {
        params: { active: 1 },
      });
      setBrands(brandDB.data);

      const descriptionDB = await api.get<IDescription[]>(
        "/populate/forecast/description",
        {
          params: { active: 1 },
        }
      );
      setDescriptions(descriptionDB.data);

      setLoadingPage(false);
    })();
  }, []);

  async function handleDeleteFile() {
    setFiles(undefined);
  }

  async function handleDeleteFileHistoric() {
    setFilesHistoric(undefined);
  }

  async function handleSubmit(item: any) {
    setLoading(true);
    try {
      if (!filesHistoric && !filesHistoric) {
        setLoading(false);
        setFileError("Arquivo e obrigatório.");
        setFileErrorHistoric("Arquivo e obrigatório.");
        return;
      }

      if (!files) {
        setLoading(false);
        return setFileError("Arquivo e obrigatório.");
      }

      if (!filesHistoric) {
        setLoading(false);
        return setFileErrorHistoric("Arquivo e obrigatório.");
      }

      const formDataTest = new FormData();
      formDataTest.append("file", files);
      await api.post("/forecast/test/file", formDataTest);

      const formDataHistoricTest = new FormData();
      formDataHistoricTest.append("file", filesHistoric);
      await api.post("/forecast/test/historic/file", formDataHistoricTest);

      const forecast = await api.post("/forecast", {
        description: item.description,
        descriptionYear: item.descriptionYear,
        title: item.title,
        brand: item.brand,
        seller: item.seller,
        historic1: item.historic1,
        historic2: item.historic2,
        historic3: item.historic3,
        historic4: item.historic4,
        closing_at: item.closing_at,
        isGoals: item.isGoals,
        equipment: Boolean(Number(item.equipment)),
        footwear: Boolean(Number(item.footwear)),
        apparel: Boolean(Number(item.apparel)),
      });

      const formData = new FormData();
      formData.append("file", files);
      formData.append("id", forecast.data.id);

      await api.post("/forecast/file", formData);

      //////////////////////////

      const formDataHistoric = new FormData();
      formDataHistoric.append("file", filesHistoric);
      formDataHistoric.append("id", forecast.data.id);

      await api.post("/forecast/historic/file", formDataHistoric);

      if (item.isGoals === "1") {
        const formDataGoals = new FormData();
        formDataGoals.append("file", item.goalsFile);
        await api.post(
          `/forecast/file-goals/${forecast.data.id}`,
          formDataGoals
        );
      }

      setLoading(false);

      addToast("Forecast criado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/previsoes");
    } catch (err) {
      const error = err as any;
      setLoading(false);
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.status === 406) {
        return addToast(
          `Falha ao importar arquivo de ${error.response.data.import}. Revise o arquivo "${error.response.data.file}"`,
          {
            appearance: "warning",
          }
        );
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleDownloadFile(uri: string, filename: string) {
    try {
      const fileResponse = await api.get(uri, {
        responseType: "blob",
      });

      fileDownload(fileResponse.data, filename);
    } catch (error) {
      return addToast(
        "Ocorreu um erro ao gerar arquivo, Tente novamente mais tarde ou entre em contato conosco (51) 3441-1000",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleTemplateFile() {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.getRow(1).height = 25;
    sheet.getRow(1).font = {
      name: "Roboto",
      family: 4,
      size: 16,
      bold: true,
    };

    const columns = [
      {
        header: "codRepresentante",
        key: "sellerCode",
        width: 25,

        alignment: {
          horizontal: "center",
          vertical: "middle",
        },
      },
      {
        header: "valorMeta",
        key: "goal",
        width: 25,

        alignment: {
          horizontal: "center",
          vertical: "middle",
        },
      },
    ];

    //@ts-ignore
    sheet.columns = columns;

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.ms-excel",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `template-metas-forecast.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }

  return (
    <CreateAndDetailLayout isLoading={loadingPage}>
      <PanelAndDetailAndCreateHeader title="Criar forecast" goBack />

      <LayoutForm onSubmit={formik.handleSubmit}>
        <h3>Informações</h3>

        <GroupInput>
          <Select
            label="Coleção"
            data={descriptions.map((item) => ({
              value: item.id,
              name: item.description,
            }))}
            {...formik.getFieldProps("description")}
            error={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : ""
            }
          />
          <Select
            label="Ano"
            data={years.map((item) => ({
              value: item.year,
              name: item.year,
            }))}
            {...formik.getFieldProps("descriptionYear")}
            error={
              formik.touched.descriptionYear && formik.errors.descriptionYear
                ? formik.errors.descriptionYear
                : ""
            }
          />
        </GroupInput>

        <GroupInput>
          <Select
            label="Marca"
            data={brands.map((item) => ({
              value: item.cod,
              name: item.name,
            }))}
            {...formik.getFieldProps("brand")}
            error={
              formik.touched.brand && formik.errors.brand
                ? formik.errors.brand
                : ""
            }
          />
          <Select
            label="Responsável"
            data={
              sellers.data?.sellers
                .filter((f) =>
                  formik.values.brand
                    ? f.brands
                        .map((brand) => brand.cod)
                        .includes(Number(formik.values.brand))
                    : false
                )
                .map((item) => ({
                  value: item.cod,
                  name: `${item.cod} - ${item.abbreviation}`,
                })) ?? []
            }
            {...formik.getFieldProps("seller")}
            error={
              formik.touched.seller && formik.errors.seller
                ? formik.errors.seller
                : ""
            }
          />
        </GroupInput>

        <Input
          label="Data finalização"
          type="date"
          {...formik.getFieldProps("closing_at")}
          error={
            formik.touched.closing_at && formik.errors.closing_at
              ? formik.errors.closing_at
              : ""
          }
        />

        <h3>Descrições do histórico</h3>

        <GroupInput>
          <Input
            label="Histórico 1"
            {...formik.getFieldProps("historic1")}
            error={
              formik.touched.historic1 && formik.errors.historic1
                ? formik.errors.historic1
                : ""
            }
          />
          <Input
            label="Histórico 2"
            {...formik.getFieldProps("historic2")}
            error={
              formik.touched.historic2 && formik.errors.historic2
                ? formik.errors.historic2
                : ""
            }
          />
        </GroupInput>
        <GroupInput>
          <Input
            label="Histórico 3"
            {...formik.getFieldProps("historic3")}
            error={
              formik.touched.historic3 && formik.errors.historic3
                ? formik.errors.historic3
                : ""
            }
          />
          <Input
            label="Histórico 4"
            {...formik.getFieldProps("historic4")}
            error={
              formik.touched.historic4 && formik.errors.historic4
                ? formik.errors.historic4
                : ""
            }
          />
        </GroupInput>

        <h3>Dados</h3>
        <GroupInput>
          <Select
            label="Equipment"
            data={[
              { name: "SIM", value: "1" },
              { name: "NÃO", value: "0" },
            ]}
            {...formik.getFieldProps("equipment")}
            error={
              formik.touched.equipment && formik.errors.equipment
                ? formik.errors.equipment
                : ""
            }
          />
          <Select
            label="Apparel"
            data={[
              { name: "SIM", value: "1" },
              { name: "NÃO", value: "0" },
            ]}
            {...formik.getFieldProps("apparel")}
            error={
              formik.touched.apparel && formik.errors.apparel
                ? formik.errors.apparel
                : ""
            }
          />
          <Select
            label="Footwear"
            data={[
              { name: "SIM", value: "1" },
              { name: "NÃO", value: "0" },
            ]}
            {...formik.getFieldProps("footwear")}
            error={
              formik.touched.footwear && formik.errors.footwear
                ? formik.errors.footwear
                : ""
            }
          />
        </GroupInput>

        <h3>Metas por representante</h3>
        <ButtonCheck
          name="isGoals"
          bgColor="rgb(50, 50, 50)"
          options={[
            { field: "Sim", value: "1" },
            { field: "Não", value: "0" },
          ]}
          setValue={(value) => {
            formik.setFieldValue("isGoals", value);
          }}
          value={formik.values.isGoals}
          error={
            formik.touched.isGoals && formik.errors.isGoals
              ? formik.errors.isGoals
              : ""
          }
        />

        {formik.values.isGoals === "1" && (
          <DropzoneForm
            label="Arquivo das metas"
            files={
              formik.values.goalsFile
                ? ([formik.values.goalsFile] as any)
                : undefined
            }
            accept={[
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ]}
            onTrash={() => formik.setFieldValue("goalsFile", undefined)}
            onFileUploaded={(e) => {
              formik.setFieldValue("goalsFile", e[0]);
              formik.handleBlur("goalsFile");
            }}
            error={
              formik.touched.goalsFile && formik.errors.goalsFile
                ? formik.errors.goalsFile
                : ""
            }
            onTemplateFile={handleTemplateFile}
          />
        )}

        <DivUploadFile>
          <div>
            <HeaderUploadFile>
              <h3>Importação de participantes</h3>

              <nav className="download-file">
                <button
                  type="button"
                  onClick={() =>
                    handleDownloadFile(
                      "forecast/download/file/import",
                      "Importação de participantes.xlsx"
                    )
                  }
                >
                  Baixar planilha modelo
                  <span>
                    <RiFileExcel2Line color="#207245" size={20} />
                    <MdArrowDownward color="#207245" size={14} />
                  </span>
                </button>
              </nav>
            </HeaderUploadFile>

            <ContainerDropZone>
              <Dropzone onFileUploaded={(e) => setSelectFile(e)} />
              <ContainerPreviews>
                {files && (
                  <li key={`${generateHash()}-${files.name}`}>
                    <img src={logoExecel} alt="Logo_Execel" />
                    <div className="fileInfo">
                      <div>
                        <strong>{files.name}</strong>
                        <span>{filesize(files.size)}</span>
                      </div>

                      <button
                        type="button"
                        onClick={() => {
                          handleDeleteFile();
                        }}
                      >
                        <IoMdTrash size={30} />
                      </button>
                    </div>
                  </li>
                )}
              </ContainerPreviews>
            </ContainerDropZone>
            {fileError && (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {fileError} </span>
              </Error>
            )}
          </div>

          <div>
            <HeaderUploadFile>
              <h3>Importação de historico</h3>

              <nav className="download-file">
                <button
                  type="button"
                  onClick={() =>
                    handleDownloadFile(
                      "forecast/download/historic/file/import",
                      "Importação de historico.xlsx"
                    )
                  }
                >
                  Baixar planilha modelo
                  <span>
                    <RiFileExcel2Line color="#207245" size={20} />
                    <MdArrowDownward color="#207245" size={14} />
                  </span>
                </button>
              </nav>
            </HeaderUploadFile>

            <ContainerDropZone>
              <Dropzone onFileUploaded={(e) => setSelectFileHistoric(e)} />
              <ContainerPreviews>
                {filesHistoric && (
                  <li key={`${generateHash()}-${filesHistoric.name}`}>
                    <img src={logoExecel} alt="Logo_Execel" />
                    <div className="fileInfo">
                      <div>
                        <strong>{filesHistoric.name}</strong>
                        <span>{filesize(filesHistoric.size)}</span>
                      </div>

                      <button
                        type="button"
                        onClick={() => {
                          handleDeleteFileHistoric();
                        }}
                      >
                        <IoMdTrash size={30} />
                      </button>
                    </div>
                  </li>
                )}
              </ContainerPreviews>
            </ContainerDropZone>
            {fileErrorHistoric && (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {fileErrorHistoric} </span>
              </Error>
            )}
          </div>
        </DivUploadFile>

        <ButtonSubmit disabled={loading}>
          {loading ? <Loading borderSize={2} size={20} /> : "Criar"}
        </ButtonSubmit>
      </LayoutForm>
    </CreateAndDetailLayout>
  );
};

export default CreateForecast;
