import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 1rem 1rem 0.1rem 1rem;

  flex: 1;
`;

export const Heading = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 0.875rem;
`;

export const Group = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 1rem;
  margin-bottom: 1rem;
`;
