import { useQuery, UseQueryResult } from "react-query";
import api from "../../service/api";

export type Brand = {
  cod: number;
  name: string;
};
export type BrandFilters = {
  cod?: number;
  name?: string;
  active?: number;
  licensed?: number;
  activeScheduling?: boolean;
  is_not_working?: boolean;
  isBrandsProvider?: boolean;
  providerId?: number;
};

export async function getBrands(
  page: number,
  filter?: BrandFilters,
  registersPerPag?: number
): Promise<Brand[]> {
  const { data } = await api.get<{ contents: Brand[] }>("/brand", {
    params: { pageRequest: page - 1, limitRequest: registersPerPag, ...filter },
  });

  return data.contents;
}

export function useBrands(
  page: number,
  filter?: BrandFilters,
  registersPerPag?: number
): UseQueryResult<Brand[]> {
  return useQuery(
    ["brands", page, filter, registersPerPag],
    () => getBrands(page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}
