import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { ButtonCheck } from "../../../components/Form/ButtonCheck";
import Dropzone from "../../../components/Form/Dropzone";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { Select } from "../../../components/Form/Select";
import Loading from "../../../components/Loading";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { useOnePublicRegisterSeller } from "../../../hook/queries/useRegisterSeller";
import api from "../../../service/api";
import { setFormikValues } from "../../../utils/setFormikValues";
import { ButtunSubmit } from "../styles";

export interface MatchParams {
  id?: string;
}

type RegisterSellerForm = {
  id: string;
  fullName: string;
  aliasName: string;
  email: string;
  phone: string;
  mobilePhone: string;
  streetAddress: string;
  number: string;
  complement: string;
  neighborhood: string;
  state: string;
  city: string;
  cnpj: string;
  corporateName: string;
  legalNature: string;
  taxClassification: string;
  coreNumber: string;
  bankName: string;
  branch: string;
  accountNumber: string;
  accountType: string;
  addressProof?: File;
  cnpjCard?: File;
  corporateContract?: File;
  invoiceFromOtherCompany?: File;
  partnersDocument?: File[];
  bankDataProof?: File;
  simpleOptionDescription?: File;
  coreDocuments?: File;
};

type ContractForm = {
  contractFileSigned?: File;
  typeReceiptContract?: string;
};

const CreatePublicRegisterSeller: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  const match = useRouteMatch<MatchParams>();
  const getRegisterSeller = useOnePublicRegisterSeller(match?.params?.id);

  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const formik = useFormik<RegisterSellerForm>({
    initialValues: {
      id: "",
      fullName: "",
      aliasName: "",
      email: "",
      phone: "",
      mobilePhone: "",
      streetAddress: "",
      number: "",
      complement: "",
      neighborhood: "",
      state: "",
      city: "",
      cnpj: "",
      corporateName: "",
      legalNature: "",
      taxClassification: "",
      coreNumber: "",
      bankName: "",
      branch: "",
      accountNumber: "",
      accountType: "",
      addressProof: undefined,
      cnpjCard: undefined,
      corporateContract: undefined,
      invoiceFromOtherCompany: undefined,
      partnersDocument: undefined,
      bankDataProof: undefined,
      simpleOptionDescription: undefined,
      coreDocuments: undefined,
    },

    validationSchema: Yup.object({
      fullName: Yup.string().required("É obrigatório"),
      aliasName: Yup.string().required("É obrigatório"),
      phone: Yup.string().required("É obrigatório"),
      email: Yup.string().email("E-mail inválido").required("É obrigatório"),
      mobilePhone: Yup.string().required("É obrigatório"),
      streetAddress: Yup.string().required("É obrigatório"),
      number: Yup.string().required("É obrigatório"),
      neighborhood: Yup.string().required("É obrigatório"),
      state: Yup.string().required("É obrigatório"),
      city: Yup.string().required("É obrigatório"),
      cnpj: Yup.string().required("É obrigatório"),
      corporateName: Yup.string().required("É obrigatório"),
      legalNature: Yup.string().required("É obrigatório"),
      taxClassification: Yup.string().required("É obrigatório"),
      coreNumber: Yup.string().required("É obrigatório"),
      bankName: Yup.string().required("É obrigatório"),
      branch: Yup.string().required("É obrigatório"),
      accountNumber: Yup.string().required("É obrigatório"),
      accountType: Yup.string().required("É obrigatório"),
      addressProof: Yup.mixed().required("É obrigatório"),
      cnpjCard: Yup.mixed().required("É obrigatório"),
      corporateContract: Yup.mixed().required("É obrigatório"),
      invoiceFromOtherCompany: Yup.mixed().required("É obrigatório"),
      partnersDocument: Yup.mixed().required("É obrigatório"),
      bankDataProof: Yup.mixed().required("É obrigatório"),
      simpleOptionDescription: Yup.mixed().required("É obrigatório"),
      coreDocuments: Yup.mixed().required("É obrigatório"),
    }),

    onSubmit: handleSubmit,
  });

  const formikContract = useFormik<ContractForm>({
    initialValues: {
      typeReceiptContract: "",
      contractFileSigned: undefined,
    },

    validationSchema: Yup.object({
      typeReceiptContract: Yup.string().required("É obrigatório"),
      contractFileSigned: Yup.mixed().required("É obrigatório"),
    }),

    onSubmit: handleContractSubmit,
  });

  async function handleSubmit(data: RegisterSellerForm) {
    setLoadingSubmit(true);
    try {
      if (data.addressProof) {
        const formDataAddressProof = new FormData();
        formDataAddressProof.append("file", data.addressProof);
        await api.post(
          `/register-seller/upload/${match.params.id}/addressProof`,
          formDataAddressProof
        );
      }
      if (data.cnpjCard) {
        const formDataCnpjCard = new FormData();
        formDataCnpjCard.append("file", data.cnpjCard);
        await api.post(
          `/register-seller/upload/${match.params.id}/cnpjCard`,
          formDataCnpjCard
        );
      }
      if (data.corporateContract) {
        const formDataCorporateContract = new FormData();
        formDataCorporateContract.append("file", data.corporateContract);
        await api.post(
          `/register-seller/upload/${match.params.id}/corporateContract`,
          formDataCorporateContract
        );
      }
      if (data.invoiceFromOtherCompany) {
        const formDataInvoiceFromOtherCompany = new FormData();
        formDataInvoiceFromOtherCompany.append(
          "file",
          data.invoiceFromOtherCompany
        );
        await api.post(
          `/register-seller/upload/${match.params.id}/invoiceFromOtherCompany`,
          formDataInvoiceFromOtherCompany
        );
      }
      if (data.partnersDocument) {
        const formDataPartnersDocument = new FormData();
        for (const filePartnersDocument of data.partnersDocument) {
          formDataPartnersDocument.append("file", filePartnersDocument);
        }
        await api.post(
          `/register-seller/upload/${match.params.id}/partnersDocument`,
          formDataPartnersDocument
        );
      }
      if (data.bankDataProof) {
        const formDataBankDataProof = new FormData();
        formDataBankDataProof.append("file", data.bankDataProof);
        await api.post(
          `/register-seller/upload/${match.params.id}/bankDataProof`,
          formDataBankDataProof
        );
      }
      if (data.simpleOptionDescription) {
        const formDataSimpleOptionDescription = new FormData();
        formDataSimpleOptionDescription.append(
          "file",
          data.simpleOptionDescription
        );
        await api.post(
          `/register-seller/upload/${match.params.id}/simpleOptionDescription`,
          formDataSimpleOptionDescription
        );
      }
      if (data.coreDocuments) {
        const formDataCoreDocuments = new FormData();
        formDataCoreDocuments.append("file", data.coreDocuments);
        await api.post(
          `/register-seller/upload/${match.params.id}/coreDocuments`,
          formDataCoreDocuments
        );
      }

      await api.patch(`/register-seller/alter-public/${data.id}`, {
        ...data,
        statusCod: 2,
      });

      history.push("/");

      addToast(
        "Informações enviadas, serão analisados pela nossa equipe internamente e será retornando o contrato para assinatura.",
        {
          appearance: "success",
        }
      );
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoadingSubmit(false);
    }
  }

  async function handleContractSubmit(data: ContractForm) {
    setLoadingSubmit(true);

    try {
      if (data.contractFileSigned) {
        const formData = new FormData();
        formData.append("file", data.contractFileSigned);
        await api.post(
          `/register-seller/upload/${match.params.id}/contractFileSigned`,
          formData
        );
      }

      await api.patch(
        `/register-seller/alter-public/${getRegisterSeller?.data?.id}`,
        {
          ...data,
          statusCod: 4,
        }
      );

      history.push("/");

      addToast("Informações enviadas.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoadingSubmit(false);
    }
  }

  useEffect(() => {
    (async () => {
      if (getRegisterSeller?.isSuccess && getRegisterSeller?.data) {
        setFormikValues(getRegisterSeller?.data, formik, setLoading);
      } else {
        setLoading(true);
      }
    })();

    // eslint-disable-next-line
  }, [getRegisterSeller?.isSuccess]);

  return (
    <CreateAndDetailLayout
      isLoading={
        getRegisterSeller?.isLoading ||
        loading ||
        ![1, 3].includes(getRegisterSeller?.data?.status.cod ?? 0)
      }
    >
      <PanelAndDetailAndCreateHeader title={"Cadastro de representante"} />

      {getRegisterSeller?.data?.status.cod === 1 && (
        <LayoutForm onSubmit={formik.handleSubmit}>
          <h3>Identificação</h3>

          <GroupInput>
            <Input
              label="Nome Completo"
              {...formik.getFieldProps("fullName")}
              error={
                formik.touched.fullName && formik.errors.fullName
                  ? formik.errors.fullName
                  : undefined
              }
            />
            <Input
              readOnly
              label="Nome de Guerra"
              {...formik.getFieldProps("aliasName")}
              error={
                formik.touched.aliasName && formik.errors.aliasName
                  ? formik.errors.aliasName
                  : undefined
              }
            />
          </GroupInput>
          <GroupInput>
            <Input
              label="E-Mail"
              {...formik.getFieldProps("email")}
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : undefined
              }
            />
            <Input
              label="Telefone"
              {...formik.getFieldProps("phone")}
              error={
                formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : undefined
              }
            />
            <Input
              label="Celular"
              {...formik.getFieldProps("mobilePhone")}
              error={
                formik.touched.mobilePhone && formik.errors.mobilePhone
                  ? formik.errors.mobilePhone
                  : undefined
              }
            />
          </GroupInput>

          <h3>Endereço</h3>

          <GroupInput>
            <Input
              label="Estado"
              {...formik.getFieldProps("state")}
              error={
                formik.touched.state && formik.errors.state
                  ? formik.errors.state
                  : undefined
              }
            />
            <Input
              label="Cidade"
              {...formik.getFieldProps("city")}
              error={
                formik.touched.city && formik.errors.city
                  ? formik.errors.city
                  : undefined
              }
            />
            <Input
              label="Bairro"
              {...formik.getFieldProps("neighborhood")}
              error={
                formik.touched.neighborhood && formik.errors.neighborhood
                  ? formik.errors.neighborhood
                  : undefined
              }
            />
          </GroupInput>

          <GroupInput>
            <Input
              label="Logradouro"
              {...formik.getFieldProps("streetAddress")}
              error={
                formik.touched.streetAddress && formik.errors.streetAddress
                  ? formik.errors.streetAddress
                  : undefined
              }
            />
            <Input
              label="Numero"
              {...formik.getFieldProps("number")}
              error={
                formik.touched.number && formik.errors.number
                  ? formik.errors.number
                  : undefined
              }
            />
            <Input
              label="Complemento"
              {...formik.getFieldProps("complement")}
              error={
                formik.touched.complement && formik.errors.complement
                  ? formik.errors.complement
                  : undefined
              }
            />
          </GroupInput>

          <h3>Identificação da Pessoa Jurídica</h3>

          <GroupInput>
            <Input
              label="CNPJ"
              {...formik.getFieldProps("cnpj")}
              error={
                formik.touched.cnpj && formik.errors.cnpj
                  ? formik.errors.cnpj
                  : undefined
              }
            />
            <Input
              label="Razão Social"
              {...formik.getFieldProps("corporateName")}
              error={
                formik.touched.corporateName && formik.errors.corporateName
                  ? formik.errors.corporateName
                  : undefined
              }
            />
          </GroupInput>
          <GroupInput>
            <Input
              label="Natureza Jurídica"
              {...formik.getFieldProps("legalNature")}
              error={
                formik.touched.legalNature && formik.errors.legalNature
                  ? formik.errors.legalNature
                  : undefined
              }
            />
            <Input
              label="Enquadramento Tributário"
              {...formik.getFieldProps("taxClassification")}
              error={
                formik.touched.taxClassification &&
                formik.errors.taxClassification
                  ? formik.errors.taxClassification
                  : undefined
              }
            />
            <Input
              label="Número CORE"
              {...formik.getFieldProps("coreNumber")}
              error={
                formik.touched.coreNumber && formik.errors.coreNumber
                  ? formik.errors.coreNumber
                  : undefined
              }
            />
          </GroupInput>

          <h3>Dados Bancários</h3>
          <GroupInput>
            <Select
              label="Tipo conta"
              {...formik.getFieldProps("accountType")}
              data={[
                { name: "Conta corrente", value: "Conta corrente" },
                { name: "Conta poupança", value: "Conta poupança" },
                { name: "Conta de pagamento", value: "Conta de pagamento" },
                { name: "Conta salário", value: "Conta salário" },
              ]}
            />

            <Input
              label="Banco"
              {...formik.getFieldProps("bankName")}
              error={
                formik.touched.bankName && formik.errors.bankName
                  ? formik.errors.bankName
                  : undefined
              }
            />
          </GroupInput>
          <GroupInput>
            <Input
              label="Agencia"
              {...formik.getFieldProps("branch")}
              error={
                formik.touched.branch && formik.errors.branch
                  ? formik.errors.branch
                  : undefined
              }
            />
            <Input
              label="Conta"
              {...formik.getFieldProps("accountNumber")}
              error={
                formik.touched.accountNumber && formik.errors.accountNumber
                  ? formik.errors.accountNumber
                  : undefined
              }
            />
          </GroupInput>

          <h3>Anexos</h3>

          <GroupInput>
            <Dropzone
              label="Comprovante de endereço"
              files={
                formik?.values?.addressProof
                  ? [formik?.values?.addressProof]
                  : undefined
              }
              onTrash={() => formik.setFieldValue("addressProof", undefined)}
              onFileUploaded={(e) => {
                formik.setFieldValue("addressProof", e[0]);
                formik.handleBlur("addressProof");
              }}
              error={
                formik.touched.addressProof && formik.errors.addressProof
                  ? formik.errors.addressProof
                  : undefined
              }
            />

            <Dropzone
              label="Cartão CNPJ"
              files={
                formik?.values?.cnpjCard
                  ? [formik?.values?.cnpjCard]
                  : undefined
              }
              onTrash={() => formik.setFieldValue("cnpjCard", undefined)}
              onFileUploaded={(e) => {
                formik.setFieldValue("cnpjCard", e[0]);
                formik.handleBlur("cnpjCard");
              }}
              error={
                formik.touched.cnpjCard && formik.errors.cnpjCard
                  ? formik.errors.cnpjCard
                  : undefined
              }
            />

            <Dropzone
              label="Contrato Social ou Requerimento Empresarial"
              files={
                formik?.values?.corporateContract
                  ? [formik?.values?.corporateContract]
                  : undefined
              }
              onTrash={() =>
                formik.setFieldValue("corporateContract", undefined)
              }
              onFileUploaded={(e) => {
                formik.setFieldValue("corporateContract", e[0]);
                formik.handleBlur("corporateContract");
              }}
              error={
                formik.touched.corporateContract &&
                formik.errors.corporateContract
                  ? formik.errors.corporateContract
                  : undefined
              }
            />
          </GroupInput>

          <GroupInput>
            <Dropzone
              label="Nota fiscal de outra empresa"
              files={
                formik?.values?.invoiceFromOtherCompany
                  ? [formik?.values?.invoiceFromOtherCompany]
                  : undefined
              }
              onTrash={() =>
                formik.setFieldValue("invoiceFromOtherCompany", undefined)
              }
              onFileUploaded={(e) => {
                formik.setFieldValue("invoiceFromOtherCompany", e[0]);
                formik.handleBlur("invoiceFromOtherCompany");
              }}
              error={
                formik.touched.invoiceFromOtherCompany &&
                formik.errors.invoiceFromOtherCompany
                  ? formik.errors.invoiceFromOtherCompany
                  : undefined
              }
            />

            <Dropzone
              label="Comprovante de dados bancários"
              files={
                formik?.values?.bankDataProof
                  ? [formik?.values?.bankDataProof]
                  : undefined
              }
              onTrash={() => formik.setFieldValue("bankDataProof", undefined)}
              onFileUploaded={(e) => {
                formik.setFieldValue("bankDataProof", e[0]);
                formik.handleBlur("bankDataProof");
              }}
              error={
                formik.touched.bankDataProof && formik.errors.bankDataProof
                  ? formik.errors.bankDataProof
                  : undefined
              }
            />
            <Dropzone
              label="Descrição de optante do Simples"
              files={
                formik?.values?.simpleOptionDescription
                  ? [formik?.values?.simpleOptionDescription]
                  : undefined
              }
              onTrash={() =>
                formik.setFieldValue("simpleOptionDescription", undefined)
              }
              onFileUploaded={(e) => {
                formik.setFieldValue("simpleOptionDescription", e[0]);
                formik.handleBlur("simpleOptionDescription");
              }}
              error={
                formik.touched.simpleOptionDescription &&
                formik.errors.simpleOptionDescription
                  ? formik.errors.simpleOptionDescription
                  : undefined
              }
            />
          </GroupInput>

          <GroupInput>
            <Dropzone
              label="Documentos do CORE"
              files={
                formik?.values?.coreDocuments
                  ? [formik?.values?.coreDocuments]
                  : undefined
              }
              onTrash={() => formik.setFieldValue("coreDocuments", undefined)}
              onFileUploaded={(e) => {
                formik.setFieldValue("coreDocuments", e[0]);
                formik.handleBlur("coreDocuments");
              }}
              error={
                formik.touched.coreDocuments && formik.errors.coreDocuments
                  ? formik.errors.coreDocuments
                  : undefined
              }
            />
            <Dropzone
              label="Documento dos sócios"
              files={formik?.values?.partnersDocument}
              onTrash={(file) =>
                formik.setFieldValue(
                  "partnersDocument",
                  formik?.values?.partnersDocument?.filter((f) => f !== file)
                )
              }
              onFileUploaded={(e) => {
                let files: File[] = [];

                if (formik?.values?.partnersDocument) {
                  files = [...e, ...formik?.values?.partnersDocument];
                }

                formik.setFieldValue("partnersDocument", files);
                formik.handleBlur("partnersDocument");
              }}
              error={
                formik.touched.partnersDocument &&
                formik.errors.partnersDocument
                  ? formik.errors.partnersDocument
                  : undefined
              }
            />
          </GroupInput>

          <ButtunSubmit type="submit" style={{ marginBottom: 0 }}>
            {loadingSubmit ? <Loading /> : "Enviar"}
          </ButtunSubmit>
        </LayoutForm>
      )}

      {getRegisterSeller?.data?.status.cod === 3 && (
        <LayoutForm onSubmit={formikContract.handleSubmit}>
          <h3>Identificação</h3>

          <GroupInput>
            <Input
              label="Nome Completo"
              readOnly
              {...formik.getFieldProps("fullName")}
            />
            <Input
              readOnly
              label="Nome de Guerra"
              {...formik.getFieldProps("aliasName")}
            />
          </GroupInput>
          <GroupInput>
            <Input label="E-Mail" readOnly {...formik.getFieldProps("email")} />
            <Input
              label="Telefone"
              readOnly
              {...formik.getFieldProps("phone")}
            />
            <Input
              label="Celular"
              {...formik.getFieldProps("mobilePhone")}
              readOnly
            />
          </GroupInput>

          <h3>Recebimento contrato</h3>

          <ButtonCheck
            name="typeReceiptContract"
            options={[
              { field: "Anexo", value: "anexo" },
              { field: "Envio", value: "envio" },
            ]}
            setValue={(value) => {
              formikContract.setFieldValue("typeReceiptContract", value);
            }}
            value={formikContract.values.typeReceiptContract}
            error={
              formikContract.touched.typeReceiptContract &&
              formikContract.errors.typeReceiptContract
                ? formikContract.errors.typeReceiptContract
                : undefined
            }
          />

          {formikContract.values.typeReceiptContract && (
            <Dropzone
              label={
                formikContract.values.typeReceiptContract === "anexo"
                  ? "Contrato assinado"
                  : "Comprovante de envio"
              }
              type="one"
              files={
                formikContract?.values?.contractFileSigned
                  ? [formikContract?.values?.contractFileSigned]
                  : undefined
              }
              onTrash={() =>
                formikContract.setFieldValue("contractFileSigned", undefined)
              }
              onFileUploaded={(e) => {
                formikContract.setFieldValue("contractFileSigned", e[0]);
                formikContract.handleBlur("contractFileSigned");
              }}
              error={
                formikContract.touched.contractFileSigned &&
                formikContract.errors.contractFileSigned
                  ? formikContract.errors.contractFileSigned
                  : undefined
              }
            />
          )}

          <ButtunSubmit type="submit" style={{ marginBottom: 0 }}>
            {loadingSubmit ? <Loading /> : "Enviar"}
          </ButtunSubmit>
        </LayoutForm>
      )}
    </CreateAndDetailLayout>
  );
};

export default CreatePublicRegisterSeller;
